@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,400;1,800;1,900&display=swap');

// temp variables
$font-raleway: 'Raleway', sans-serif;
$white: #ffffff;
$black: #000000;
$pageBackground: $white;
$fontColor: $black;

html {
  font-family: $font-raleway;
  font-variant-numeric: lining-nums proportional-nums;
  background: $pageBackground;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-raleway;
  line-height: 1.5;
  font-size: 14px;
  background: $pageBackground;
  color: $fontColor;
}

input,
button,
textarea {
  font-family: $font-raleway;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

h1 {
  font-size: 35px;
  font-weight: 800;
  line-height: 2.09;
}

h2 {
  font-size: 24px;
  font-weight: 800;
}

h3 {
  font-size: 18px;
  font-weight: 700;
}

h4 {
  font-size: 14px;
  font-weight: 700;
}

a {
  text-decoration: none;
  color: var(--primary-main5);
}
