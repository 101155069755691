.item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px 10px 7px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;

  &.clickable {
    cursor: pointer;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .withTags {
      align-items: flex-start;
      div > h4 {
        margin-bottom: 8px;
      }
    }
  }

  .delimiter {
    padding: 0 10px;
    opacity: 0.5;
  }
  .rowWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .prefixLabel {
    margin-right: 5px;
  }

  .lineWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .icon {
    margin-right: 12px;
  }
  .contactIcon {
    margin-left: 8px;
    height: 30px;
    width: 30px;
  }

  h4 {
    margin: 0px;
    font-size: 15px;
    font-weight: 500;
    line-height: 17px;
    color: #fff;
  }

  .arrow {
    opacity: 0.5;
    height: 15px;
    width: 11px;
  }
}

.value {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;

  & p {
    margin: 0px;
  }
}

.regular {
  margin-left: 0px;
}

.indented {
  margin-left: 65px;
}

.newTabIcon {
  height: 15px;
  width: 15px;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

.actionIconsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.actionIcon {
  height: 15px;
  width: 15px;
  opacity: 0.5;
  margin-left: 10px;
  &:hover {
    opacity: 1;
  }
}

.alignCenter {
  display: flex;
  align-self: center;
}

.dropdown {
  // remove all styles
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
}
