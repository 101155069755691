.root {
  background: linear-gradient(0deg, #54b8aa -0.07%, #2c598b 100%);
  box-shadow: 0px 3px 44px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  height: 100vh;
  padding: 32px 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  overflow-y: scroll;
}

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: 47px;
  align-items: center;
}

.logo {
  height: 51px;
}

h3 {
  font-size: 28px;
  font-weight: 300;
  margin: 14px 0;
}

h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
}

h5 {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.callToAction {
  font-weight: 500;
  text-align: center;
  margin-top: 100px;
}

.itemsWrapper {
  margin-top: 20px;
  width: 100%;
}

.loader {
  align-self: center;
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  .footerLink {
    text-decoration: underline;
    cursor: pointer;
  }
}

.searchDescription {
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  margin-top: 8px;
}
