.root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.header {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-right: 1rem;
  flex: 1;
  justify-content: space-between;

  .headerInfo {
    display: flex;

    .iconContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      .backIcon {
        cursor: pointer;
        font-size: 24px;
        margin-right: 1.5rem;
      }
    }

    .headerText {
      .title {
        margin: 0;
      }

      .pageTitle {
        margin: 0;
      }
    }
  }
}

.title {
  color: var(--gray-light, #f0f0f0);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.keywords {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
  margin-top: 16px;
  max-height: 20vh;
  overflow-y: scroll;
  min-height: 70px;
}

.newKeywords {
  flex: 2;
}

.suggestedKeywords {
  flex: 3;
  max-height: 40vh;
}

.keyword {
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 50px;
  height: 37px;
  border-radius: 24px;
  border: 1px solid var(--gray-light, #f0f0f0);
  background: var(--color-background, #f5f5f5);
  padding: 0 1rem;

  &:hover:not(.disabledKeyword) {
    background: #ffdd99;
  }
}

.disabledKeyword {
  border: none;
  background: var(--color-warning-base, #faad14);
  cursor: default;
}

.keywordText {
  color: var(--blue-extra-dark, #243b54);
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  padding: 0 0.2rem;
}

.keywordBold {
  font-weight: 700;
}

.disabledKeywordText {
  font-weight: 700;
}

.removeIcon {
  cursor: pointer;
  color: var(--blue-extra-dark, #243b54);
  margin-left: 1rem;
}

.actionButtons {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.actionButton {
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 37px;
  border-radius: 24px;
  background: transparent;
  border: 1px solid var(--gray-light, #f0f0f0);
  opacity: 0.9;

  &:hover {
    &:enabled {
      background: var(--gray-light, #f0f0f0);
      .actionButtonText {
        color: var(--primary-dark, #0c1934);
        opacity: 0.9;
      }

      .actionButtonIcon {
        color: var(--primary-dark, #0c1934);
        opacity: 0.9;
      }
    }
  }

  .actionButtonText {
    color: var(--gray-light, #f0f0f0);
    font-weight: 600;
  }

  .actionButtonIcon {
    color: var(--gray-light, #f0f0f0);
  }
}

.actionButton[disabled] {
  opacity: 0.3;
  cursor: auto;
}

.search {
  height: 50px;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 0px 24px;
  cursor: pointer;

  &:focus-within {
    background-color: var(--gray-light, #f0f0f0);
  }
}

.input {
  background: none;
  border: none;
  height: 100%;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  color: var(--gray-light, #f0f0f0);

  &:focus-visible {
    outline: none;
    color: var(--primary-dark, #0c1934);
  }

  &::placeholder {
    color: var(--gray-light, #f0f0f0);
    font-weight: 400;
  }

  &:focus-within::placeholder {
    color: var(--gray-medium, #d9d9d9);
    font-weight: 500;
  }

  &:focus-within + .icon {
    color: var(--primary-dark, #0c1934);
  }
}

.icon {
  height: 17px;
  width: 17px;
  margin: 17px 0px;
  fill: var(--gray-light, #f0f0f0);
}

.noResults {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.addKeywordButton {
  display: flex;
  justify-content: space-evenly;
}
