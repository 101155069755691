$max-height: 175px;

.drugClassIcon {
  align-self: flex-end;
  height: 16px;
  width: 16px;
}

.value {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;

  & p {
    margin: 0px;
  }
}

.largeValue {
  max-height: $max-height;
  overflow: hidden;
}

.rightSideIcons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.expand {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.expandText {
  padding-right: 8px;
}

:export {
  maxHeight: $max-height;
}
