.root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.header {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  flex: 1;
  justify-content: space-between;

  .headerInfo {
    display: flex;
    justify-content: center;
    align-items: center;

    .backIcon {
      cursor: pointer;
      font-size: 24px;
      margin-right: 1.5rem;
    }

    .title {
      margin: 0;
      font-size: 24px;
      font-weight: 700;
    }
  }
}

.label {
  color: var(--gray-light, #f0f0f0);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.actionButtons {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.actionButton {
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 37px;
  border-radius: 24px;
  background: transparent;
  border: 1px solid var(--gray-light, #f0f0f0);
  opacity: 0.9;

  &:hover {
    &:enabled {
      background: var(--gray-light, #f0f0f0);
      .actionButtonText {
        color: var(--primary-dark, #0c1934);
        opacity: 0.9;
      }

      .actionButtonIcon {
        color: var(--primary-dark, #0c1934);
        opacity: 0.9;
      }
    }
  }

  .actionButtonText {
    color: var(--gray-light, #f0f0f0);
    font-weight: 600;
  }

  .actionButtonIcon {
    color: var(--gray-light, #f0f0f0);
  }
}

.actionButton[disabled] {
  opacity: 0.3;
  cursor: auto;
}

textarea {
  margin-top: 1.5rem;
  resize: none;
  display: flex;
  width: 100%;
  min-height: 265px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: 10px;
  padding: 0px 16px;
  border: none;
  padding-top: 10px;
  line-height: 140%;

  &::placeholder {
    color: var(--very-light-grey, #c3c3c3);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
  }
}

.tagWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 0;
  margin-top: 1rem;
  margin-bottom: 1.5rem;

  .tag {
    margin: 0;
    display: flex;
    padding: 8px 20px;
    margin-right: 0;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    background: var(--gray-light, #f0f0f0);
    color: var(--primary-dark, #0c1934);
    cursor: pointer;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    &:hover {
      background: #ffdd99;
    }

    &.selected {
      background: var(--color-warning-base, #faad14);
      color: var(--primary-dark, #0c1934);
    }
  }
}
