.clickAndScan {
  text-align: left;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 15em;
  margin-left: 0.3rem;
}

.qrContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.closeModalBtn {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  display: block;
  margin: 2em 2em 2em auto; // stiky to right with some margin
}

.qrCode {
  border: none;
  border-radius: 1.3em;
  padding: 1.5em;
  background: #fff;
}

.qrLarge {
  height: 16rem;
  width: 16rem;
}

.closeIcon {
  color: #fff;
  font-family: Font Awesome 6 Pro;
  font-size: 3rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  &:hover {
    opacity: 0.8;
  }
}

dialog {
  backdrop-filter: blur(0.375em);
  min-height: 100%;
  min-width: 100%;
  border: none;
  border-radius: 1.25em;
  background: rgba(0, 0, 0, 0.6);

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      color: #fff;
      text-align: center;
      font-size: 1.625rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      max-width: 20em;
    }
  }

  &[open] {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: 0.3s forwards ease-in-out grow;
  }

  &.close {
    animation: 0.3s forwards ease-in-out shrink;
  }
}

@keyframes grow {
  0% {
    transform: scale(0.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes shrink {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.1);
  }
}
