.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  img {
    width: 10rem;
    height: 13rem;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.5rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; // for Firefox
  }

  p {
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 500;
  }
}
