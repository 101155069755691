.searchHeader {
  margin: 24px 0 8px;
}

.searchTerm {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
}

.results {
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
}

.upToDate {
  font-size: 16px;
  text-align: center;
  margin-top: 30px;
}

.upToDateLink {
  color: white;
  text-decoration: underline;
  font-weight: 500;
}

.actionButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 37px;
  border-radius: 12px;
  background: transparent;
  border: 1px solid var(--gray-light, #f0f0f0);
  opacity: 0.9;
  color: var(--gray-light, #f0f0f0);
  font-weight: 600;
  margin-top: 16px;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: var(--primary-dark, #0c1934);
    background: var(--gray-light, #f0f0f0);
    opacity: 0.9;
  }

  .actionButtonIcon {
    color: var(--gray-light, #f0f0f0);
  }
}
